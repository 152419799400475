<template>
  <header id="page-header">
    <h1 class="h1">Create Youth Action Council</h1>
  </header>
  <div class="container py-5">
    <div class="row">
      <div class="m-4 col">
        <ul class="nav nav-tabs mb-5">
          <li class="nav-item">
            <a href="#video" class="nav-link active" data-bs-toggle="tab">Youth Action Council Video</a>
          </li>
          <li class="nav-item">
            <a href="#job" class="nav-link" data-bs-toggle="tab">Job Description</a>
          </li>
          <li class="nav-item">
            <a href="#surveying" class="nav-link" data-bs-toggle="tab">Surveying Youth</a>
          </li>
          <li class="nav-item">
            <a href="#assessment" class="nav-link" data-bs-toggle="tab">Youth Assessment</a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane fade show active" id="video">
            <div class="container-fluid video-container mb-3">
              <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/641270573?h=1ab0e52fcd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="YAC"></iframe></div>
            </div>
          </div>
          <div class="tab-pane fade" id="job">
            <div class="row">
              <div class="col">
                <h4 class="h4 mb-4">Youth Action Council Facilitator Job Description</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-lg-6">
                <h6 class="h6 fw-bold">Title</h6>
                <p class="p">Youth Action Council Facilitator</p>
                <h6 class="h6 fw-bold mt-2">Summary</h6>
                <p class="p">
                  The Youth Action Council Facilitator is an enabler, in the best sense of the word. The Facilitator empowers Youth Action Council (YAC) members to inform every aspect of their Mosaic Village initiative with youth perspectives, as well as the unique insights some members have earned through lived experience navigating housing instability alone.
                </p>
                <h6 class="h6 fw-bold mt-2">Responsibilities</h6>
                <ul class="my-2">
                  <li>
                    Build and develop the Youth Action Council (YAC) by:
                    <ul>
                      <li>Recruiting new YAC members.</li>
                      <li>Fostering a safe and welcoming environment where members feel respected and valued.</li>
                      <li>Learning about each member’s particular interests, strengths and gifts, and helping them use and develop those strengths in their work with the YAC.</li>
                    </ul>
                  </li>
                  <li>Coach and mentor members as emerging leaders.</li>
                  <li>Work one-on-one with individual members to help them navigate challenges and work toward their goals.</li>
                  <li>Develop a rough framework of programs and events, leaving plenty of room for YAC members to take it on and make it their own.</li>
                  <li>Facilitate YAC meetings with a light hand that elevates all voices, keeps ownership in members’ hands, and supports moving conversations forward.</li>
                  <li>Work with partners who want to embed youth voice in their programs, either by engaging the YAC, or building their own.</li>
                  <li>Represent and advocate for the YAC and the larger Mosaic Village initiative.</li>
                  <li>Actively look for ways to break down barriers and get things done.</li>
                  <li>Work with YAC members and partners to identify gaps, and collaborate on creative ways to fill them.</li>
                  <li>Develop and administer (or contract for) training for YAC members and for partners.</li>
                  <li>Manage YAC meeting logistics: create the schedule and agenda, coordinate guests and resources needed for focus topics, order meals, distribute stipend payments to members, track action items and to-do lists, and coordinate with partners.</li>
                </ul>
              </div>
              <div class="col-md-12 col-lg-6">
                <h6 class="h6 fw-bold">Skills and Experience</h6>
                <ul class="my-2">
                  <li>Professional experience working with young people from a wide range of backgrounds.</li>
                  <li>Direct experience working with youth experiencing particularly challenging circumstances such as homelessness, addiction, exploitation, being unaccompanied, in the foster care system, or in the court system.</li>
                  <li>Excellent communication skills, and the ability to seamlessly shift tone, style and language to fit the audience.</li>
                  <li>Event planning and meeting facilitation experience.</li>
                  <li>Youth outreach and engagement experience.</li>
                  <li>Professional networking skills.</li>
                </ul>
                <h6 class="h6 fw-bold mt-2">Personal Characteristics</h6>
                <ul class="my-2 ps-4 ms-4">
                  <li>Collaborative and flexible.</li>
                  <li>Warm and approachable.</li>
                  <li>Empathetic and an excellent listener.</li>
                  <li>Highly observant and sensitive to nuance and what’s left unsaid.</li>
                  <li>Genuinely respects young people and enjoys their company.</li>
                  <li>Deeply committed to the right of all people to be safe, secure, and sheltered.</li>
                </ul>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <a class="btn btn-primary d-print-none" href="/docs/JobDescriptionTemplate.docx">Download Job Description</a>
            </div>
          </div>
          <div class="tab-pane fade" id="surveying">
            <div class="row">
              <div class="col-md-12">
                <h4 class="h4 mt-md-3">Special considerations when surveying youth</h4>
                <p class="p">
                  While surveys can help you better understand the needs and experiences of unhoused youth in your community, how you administer them can affect your response rate, the accuracy of your data, and even the emotional wellbeing of the people you’re trying to help.
                </p>
                <p class="p">
                  Beyond the basics of good survey design, here are a few things to keep in mind when gathering information from young people experiencing housing instability, or other vulnerable populations.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                  <ul class="ps-4 ms-4">
                    <li>Don't just promise confidentiality; make sure that you provide true anonymity. Have respondents self-administer the survey and submit completed questionnaires in a way that reinforces that nobody could tie their responses to them. With paper surveys, that might mean providing privacy envelopes or collection boxes. Digital surveys should be completed where respondents don’t feel like someone is looking over their shoulders.</li>
                  </ul>
              </div>
              <div class="col-md-6">
                <ul class="my-2 ps-4 ms-4">
                  <li>Deeply personal questions can feel invasive, but even questions that seem straightforward—asking where they slept last night—can feel risky, unless respondents feel their anonymity is protected.</li>
                  <li>Be wary of asking about traumatic events. Make sure you truly need that information, and weigh your interest in the data against the possibility of triggering a response that you won’t be able to help mitigate.</li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mb-md-5">
                <p class="p">
                  Your new Youth Action Council can help identify problematic questions or unintended consequences, and can also help field surveys.
                </p>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="assessment">
            <h4 class="h4 mt-md-3">Youth Assessment</h4>
              <YouthAssessmentForm></YouthAssessmentForm>
              <div class="d-flex justify-content-center">
                <a class="btn btn-primary d-print-none" href="/docs/YouthAssessment.docx">Download Youth Assessment</a>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container py-5">
    <div class="row">
      <div class="col">
        <router-link
          tag="button"
          class="btn btn-primary float-start"
          to="/core-team">
          <i class="bi bi-chevron-left"></i>
        </router-link>
        <router-link
          tag="button"
          class="btn btn-primary float-end"
          to="/common-agenda">
          <i class="bi bi-chevron-right"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import YouthAssessmentForm from '@/components/YouthAssessmentForm';

export default {
  name: 'YouthActionCouncilPage',
  components: {
    YouthAssessmentForm,
  },
};
</script>

<style lang="scss" scoped>
.nav-tabs .nav-link.active {
  color: $info;
}
h4 {
  &.h4 {
    font-size: 1.25rem;
    font-weight: 700;
  }
}
</style>
