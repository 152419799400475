<template>
  <div class="container-fluid mv-table-container mt-2 mb-4">
    <div class="row py-3">
      <div class="col">
        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="howOldAreYou">How old are you?:</label>
        <input
          v-model="howOldAreYou"
          placeholder="How old are you..."
          class="form-control mv-input__input d-print-none"
          type="number"
          id="howOldAreYou"
          name="howOldAreYou">
      </div>
      <div class="col">
        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="haveYouEverBeenHomeless">Have you ever been homeless?</label>
        <select
          v-model="haveYouEverBeenHomeless"
          class="form-select form-select-sm mv-input__select d-print-none"
          id="haveYouEverBeenHomeless"
          name="haveYouEverBeenHomeless">
          <option selected disabled value="0">Have you ever been homeless?</option>
          <option v-for="(option, optionVal) in yesNoOpts" :key="optionVal">{{ option }}</option>
        </select>
      </div>
      <div class="col">
        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="whereDidYouSleepLastNight">Where did you sleep last night?</label>
        <select
          v-model="whereDidYouSleepLastNight"
          class="form-select form-select-sm mv-input__select d-print-none"
          id="whereDidYouSleepLastNight"
          name="whereDidYouSleepLastNight">
          <option selected disabled value="0">Where did you sleep last night?</option>
          <option v-for="(option, optionVal) in sleptInLastThreeYearsOpts" :key="optionVal">{{ option }}</option>
        </select>
      </div>
    </div>
    <div class="row py-3">
      <div class="col">
        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="sleptInLastThreeYears">Thinking about the past three years, what kinds of places have you slept for at least five nights in a row? (select all that apply)</label>
        <select
          style="height: 300px;"
          multiple
          v-model="sleptInLastThreeYears"
          class="form-select form-select-sm mv-input__select d-print-none"
          id="sleptInLastThreeYears"
          name="sleptInLastThreeYears">
          <option selected disabled value="0">What kinds of places have you slept for at least five nights in a row?</option>
          <option v-for="(option, optionVal) in sleptInLastThreeYearsOpts" :key="optionVal">{{ option }}</option>
        </select>
      </div>
      <div class="col">
        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="contributingFactors">Thinking back to your most recent experience, what factors contributed to you <strong>becoming</strong> homeless? (select all that apply)</label>
        <select
          style="height: 300px;"
          multiple
          v-model="contributingFactors"
          class="form-select form-select-sm mv-input__select d-print-none"
          id="contributingFactors"
          name="contributingFactors">
          <option selected disabled value="0">What factors contributed to you becoming homeless?</option>
          <option v-for="(option, optionVal) in contributingFactorsOpts" :key="optionVal">{{ option }}</option>
        </select>
      </div>
      <div class="col">
        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="helpfulSupports"><strong>Before you became homeless</strong>, what kinds of support would have helped you stay off the streets?</label>
        <select
          style="height: 300px;"
          multiple
          v-model="helpfulSupports"
          class="form-select form-select-sm mv-input__select d-print-none"
          id="helpfulSupports"
          name="helpfulSupports">
          <option selected disabled value="0">What kinds of support would have helped you stay off the streets?</option>
          <option v-for="(option, optionVal) in helpfulSupportOpts" :key="optionVal">{{ option }}</option>
        </select>
      </div>
    </div>
    <div class="row py-3">
      <div class="col">
        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="helpReceivedWas">In general, did you find the help you have received:</label>
        <select
          v-model="helpReceivedWas"
          class="form-select form-select-sm mv-input__select d-print-none"
          id="helpReceivedWas"
          name="helpReceivedWas">
          <option selected disabled value="0">In general, did you find the help you have received:</option>
          <option v-for="(option, optionVal) in helpReceivedWasOpts" :key="optionVal">{{ option }}</option>
        </select>
      </div>
      <div class="col">
        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="singleMostHelpfulSupport">What is the single most helpful type of assistance you received?</label>
        <input
          v-model="singleMostHelpfulSupport"
          placeholder="Most helpful type of assistance you received..."
          class="form-control mv-input__input d-print-none"
          type="text"
          id="singleMostHelpfulSupport"
          name="singleMostHelpfulSupport">
      </div>
      <div class="col">
        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="singleLeastHelpfulSupport">What is the single least helpful type of assistance you received?</label>
        <input
          v-model="singleLeastHelpfulSupport"
          placeholder="least helpful type of assistance you received..."
          class="form-control mv-input__input d-print-none"
          type="text"
          id="singleLeastHelpfulSupport"
          name="singleLeastHelpfulSupport">
      </div>
    </div>
    <div class="row py-3">
      <div class="col">
        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="goWhenNeedPlace">Where do you go when you need a place to be?</label>
        <input
          v-model="goWhenNeedPlace"
          placeholder="Where do you go when you need a place to be..."
          class="form-control mv-input__input d-print-none"
          type="text"
          id="goWhenNeedPlace"
          name="goWhenNeedPlace">
      </div>
      <div class="col">
        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="goWhenHungry">Where do you go when you're hungry?</label>
        <input
          v-model="goWhenHungry"
          placeholder="Where do you go when you're hungry..."
          class="form-control mv-input__input d-print-none"
          type="text"
          id="goWhenHungry"
          name="goWhenHungry">
      </div>
      <div class="col">
        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="goWhenNeedSomething">When you need something (clothes, school supplies, etc.), how do you get it?</label>
        <input
          v-model="goWhenNeedSomething"
          placeholder="Where do you go when you need something..."
          class="form-control mv-input__input d-print-none"
          type="number"
          id="goWhenNeedSomething"
          name="goWhenNeedSomething">
      </div>
    </div>
    <div class="row py-3">
      <div class="col">
        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="adultToConfideIn">Is there an adult you trust who you can confide in?</label>
        <select
          v-model="adultToConfideIn"
          class="form-select form-select-sm mv-input__select d-print-none"
          id="adultToConfideIn"
          name="adultToConfideIn">
          <option selected disabled value="0">Is there an adult you trust who you can confide in?</option>
          <option v-for="(option, optionVal) in yesNoOpts" :key="optionVal">{{ option }}</option>
        </select>
      </div>
      <div class="ocl">

        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="whereMetAdult">Where did you meet this person?</label>
        <input
          v-model="whereMetAdult"
          placeholder="Where did you meet this person..."
          class="form-control mv-input__input d-print-none"
          type="text"
          id="whereMetAdult"
          name="whereMetAdult">
      </div>
    </div>
    <div class="row py-3">
      <h5>Demographic Questions</h5>
      <div class="col">
        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="genderIdentity">Gender Identity</label>
        <select
          v-model="genderIdentity"
          class="form-select form-select-sm mv-input__select d-print-none"
          id="genderIdentity"
          name="genderIdentity">
          <option selected disabled value="0">Gender Identity</option>
          <option v-for="(option, optionVal) in genderIdentityOpts" :key="optionVal">{{ option }}</option>
        </select>
      </div>
      <div class="col">
        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="race">Race (select all that apply)</label>
        <select
          multiple
          v-model="race"
          class="form-select form-select-sm mv-input__select d-print-none"
          id="race"
          name="race">
          <option selected disabled value="0">Race</option>
          <option v-for="(option, optionVal) in raceOpts" :key="optionVal">{{ option }}</option>
        </select>
      </div>
    </div>
    <div class="row py-3">
      <div class="col">
        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="hispanicLatino">Hispanic/Latino</label>
        <select
          v-model="hispanicLatino"
          class="form-select form-select-sm mv-input__select d-print-none"
          id="hispanicLatino"
          name="hispanicLatino">
          <option selected disabled value="0">Hispanic/Latino</option>
          <option v-for="(option, optionVal) in yesNoOpts" :key="optionVal">{{ option }}</option>
        </select>
      </div>
      <div class="col">
        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="sexualOrientation">Sexual Orientation</label>
        <select
          v-model="sexualOrientation"
          class="form-select form-select-sm mv-input__select d-print-none"
          id="sexualOrientation"
          name="sexualOrientation">
          <option selected disabled value="0">Sexual Orientation</option>
          <option v-for="(option, optionVal) in sexualOrientationOpts" :key="optionVal">{{ option }}</option>
        </select>
      </div>
    </div>
    <div class="row py-3">
      <div class="col">
        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="diplomaOrGED">Do you have a high school diploma or GED?</label>
        <select
          v-model="diplomaOrGED"
          class="form-select form-select-sm mv-input__select d-print-none"
          id="diplomaOrGED"
          name="diplomaOrGED">
          <option selected disabled value="0">Do you have a high school diploma or GED?</option>
          <option v-for="(option, optionVal) in yesNoOpts" :key="optionVal">{{ option }}</option>
        </select>
      </div>
      <div class="col">

        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="currentlyInSchool">Are you in school right now?</label>
        <select
          v-model="currentlyInSchool"
          class="form-select form-select-sm mv-input__select d-print-none"
          id="currentlyInSchool"
          name="currentlyInSchool">
          <option selected disabled value="0">Are you in school right now?</option>
          <option v-for="(option, optionVal) in yesNoOpts" :key="optionVal">{{ option }}</option>
        </select>
      </div>
      <div class="col">
        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="employmentStatus">Current employment status</label>
        <select
          v-model="employmentStatus"
          class="form-select form-select-sm mv-input__select d-print-none"
          id="employmentStatus"
          name="employmentStatus">
          <option selected disabled value="0">Current employment status</option>
          <option v-for="(option, optionVal) in currentEmploymentStatusOpts" :key="optionVal">{{ option }}</option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name: 'YouthAssessmentForm',
  setup() {
    const store = useStore();

    return {
      whereDidYouSleepLastNightOpts: computed(() => store.state.youthAssessment.whereDidYouSleepLastNightOpts),
      contributingFactorsOpts: computed(() => store.state.youthAssessment.contributingFactorsOpts),
      helpfulSupportOpts: computed(() => store.state.youthAssessment.helpfulSupportOpts),
      sleptInLastThreeYearsOpts: computed(() => store.state.youthAssessment.sleptInLastThreeYearsOpts),
      helpReceivedWasOpts: computed(() => store.state.youthAssessment.helpReceivedWasOpts),
      genderIdentityOpts: computed(() => store.state.youthAssessment.genderIdentityOpts),
      raceOpts: computed(() => store.state.youthAssessment.raceOpts),
      sexualOrientationOpts: computed(() => store.state.youthAssessment.sexualOrientationOpts),
      currentEmploymentStatusOpts: computed(() => store.state.youthAssessment.currentEmploymentStatusOpts),
      yesNoOpts: computed(() => store.state.youthAssessment.yesNoOpts),
      howOldAreYou: computed({
        get: () => store.state.youthAssessment.howOldAreYou,
        set: (value) => store.dispatch('youthAssessment/updateProp', { prop: 'howOldAreYou', value }),
      }),
      haveYouEverBeenHomeless: computed({
        get: () => store.state.youthAssessment.haveYouEverBeenHomeless,
        set: (value) => store.dispatch('youthAssessment/updateProp', { prop: 'haveYouEverBeenHomeless', value }),
      }),
      whereDidYouSleepLastNight: computed({
        get: () => store.state.youthAssessment.whereDidYouSleepLastNight,
        set: (value) => store.dispatch('youthAssessment/updateProp', { prop: 'whereDidYouSleepLastNight', value }),
      }),
      sleptInLastThreeYears: computed({
        get: () => store.state.youthAssessment.sleptInLastThreeYears,
        set: (value) => store.dispatch('youthAssessment/updateProp', { prop: 'sleptInLastThreeYears', value }),
      }),
      contributingFactors: computed({
        get: () => store.state.youthAssessment.contributingFactors,
        set: (value) => store.dispatch('youthAssessment/updateProp', { prop: 'contributingFactors', value }),
      }),
      helpfulSupports: computed({
        get: () => store.state.youthAssessment.helpfulSupports,
        set: (value) => store.dispatch('youthAssessment/updateProp', { prop: 'helpfulSupports', value }),
      }),
      helpReceivedWas: computed({
        get: () => store.state.youthAssessment.helpReceivedWas,
        set: (value) => store.dispatch('youthAssessment/updateProp', { prop: 'helpReceivedWas', value }),
      }),
      singleMostHelpfulSupport: computed({
        get: () => store.state.youthAssessment.singleMostHelpfulSupport,
        set: (value) => store.dispatch('youthAssessment/updateProp', { prop: 'singleMostHelpfulSupport', value }),
      }),
      singleLeastHelpfulSupport: computed({
        get: () => store.state.youthAssessment.singleLeastHelpfulSupport,
        set: (value) => store.dispatch('youthAssessment/updateProp', { prop: 'singleLeastHelpfulSupport', value }),
      }),
      goWhenNeedPlace: computed({
        get: () => store.state.youthAssessment.goWhenNeedPlace,
        set: (value) => store.dispatch('youthAssessment/updateProp', { prop: 'goWhenNeedPlace', value }),
      }),
      goWhenHungry: computed({
        get: () => store.state.youthAssessment.goWhenHungry,
        set: (value) => store.dispatch('youthAssessment/updateProp', { prop: 'goWhenHungry', value }),
      }),
      goWhenNeedSomething: computed({
        get: () => store.state.youthAssessment.goWhenNeedSomething,
        set: (value) => store.dispatch('youthAssessment/updateProp', { prop: 'goWhenNeedSomething', value }),
      }),
      adultToConfideIn: computed({
        get: () => store.state.youthAssessment.adultToConfideIn,
        set: (value) => store.dispatch('youthAssessment/updateProp', { prop: 'adultToConfideIn', value }),
      }),
      whereMetAdult: computed({
        get: () => store.state.youthAssessment.whereMetAdult,
        set: (value) => store.dispatch('youthAssessment/updateProp', { prop: 'whereMetAdult', value }),
      }),
      genderIdentity: computed({
        get: () => store.state.youthAssessment.genderIdentity,
        set: (value) => store.dispatch('youthAssessment/updateProp', { prop: 'genderIdentity', value }),
      }),
      race: computed({
        get: () => store.state.youthAssessment.race,
        set: (value) => store.dispatch('youthAssessment/updateProp', { prop: 'race', value }),
      }),
      hispanicLatino: computed({
        get: () => store.state.youthAssessment.hispanicLatino,
        set: (value) => store.dispatch('youthAssessment/updateProp', { prop: 'hispanicLatino', value }),
      }),
      sexualOrientation: computed({
        get: () => store.state.youthAssessment.sexualOrientation,
        set: (value) => store.dispatch('youthAssessment/updateProp', { prop: 'sexualOrientation', value }),
      }),
      diplomaOrGED: computed({
        get: () => store.state.youthAssessment.diplomaOrGED,
        set: (value) => store.dispatch('youthAssessment/updateProp', { prop: 'diplomaOrGED', value }),
      }),
      currentlyInSchool: computed({
        get: () => store.state.youthAssessment.currentlyInSchool,
        set: (value) => store.dispatch('youthAssessment/updateProp', { prop: 'currentlyInSchool', value }),
      }),
      employmentStatus: computed({
        get: () => store.state.youthAssessment.employmentStatus,
        set: (value) => store.dispatch('youthAssessment/updateProp', { prop: 'employmentStatus', value }),
      }),
    };
  },
};
</script>
